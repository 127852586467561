import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Train, Download, MousePointerClick } from 'lucide-react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

const RailwaySelector = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<L.Map | null>(null);
  const [selectedArea, setSelectedArea] = useState<L.LatLngBounds | null>(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (!mapRef.current || mapInstanceRef.current) return;
    
    mapInstanceRef.current = L.map(mapRef.current, {
      center: [51.505, -0.09],
      zoom: 13,
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(mapInstanceRef.current);

    L.tileLayer('https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png', {
      attribution: '© OpenRailwayMap contributors',
      opacity: 0.8
    }).addTo(mapInstanceRef.current);

    const drawnItems = new L.FeatureGroup();
    mapInstanceRef.current.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      draw: {
        polygon: false,
        circle: false,
        polyline: false,
        circlemarker: false,
        marker: false,
        rectangle: {
          shapeOptions: {
            color: '#3730a3',
            fillOpacity: 0.3
          }
        }
      },
      edit: {
        featureGroup: drawnItems
      }
    });

    mapInstanceRef.current.addControl(drawControl);

    mapInstanceRef.current.on(L.Draw.Event.CREATED, (e) => {
      drawnItems.clearLayers();
      drawnItems.addLayer(e.layer);
      setSelectedArea(e.layer.getBounds());
    });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, []);

  const downloadRailData = async () => {
    if (!selectedArea) return;
    
    setLoading(true);
    try {
      const query = `
        [out:json][timeout:25];
        (
          way["railway"="rail"](${selectedArea.getSouth()},${selectedArea.getWest()},${selectedArea.getNorth()},${selectedArea.getEast()});
        );
        out body;
        >;
        out skel qt;
      `;
      
      const response = await fetch('https://overpass-api.de/api/interpreter', {
        method: 'POST',
        body: query
      });
      
      const data = await response.json();
      
      const tracks = {
        points: data.elements
          .filter((el: { type: string }) => el.type === "node")
          .map((node: { lon: number; lat: number }) => ({
            X: (node.lon * 111319.9),
            Y: 0,
            Z: (node.lat * 111319.9)
          }))
      };
      
      const blob = new Blob([JSON.stringify(tracks)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'railway_data.json';
      a.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-6xl bg-slate-50">
      <CardHeader className="border-b bg-white">
        <CardTitle className="flex items-center gap-2">
          <Train className="h-6 w-6 text-indigo-600" />
          Railway Data Extractor
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6 space-y-6">
        <div className="rounded-xl overflow-hidden border shadow-sm">
          <div ref={mapRef} className="h-[600px] w-full" />
        </div>
        
        <div className="flex gap-4 justify-between">
          <Alert className="flex-1">
            <MousePointerClick className="h-4 w-4" />
            <AlertDescription>
              Use the rectangle tool to select railway area
            </AlertDescription>
          </Alert>
          
          <Button 
            className="bg-indigo-600 hover:bg-indigo-700"
            onClick={downloadRailData}
            disabled={!selectedArea || loading}
          >
            <Download className="mr-2 h-4 w-4" />
            {loading ? 'Downloading...' : 'Download Railway Data'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default RailwaySelector;