// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage';
import ToolsPage from './pages/ToolsPage';
import RunwayPredictionPage from './pages/RunwayPredictionPage';
import AircraftIdentificationPage from './pages/AircraftIdentPage';
import AboutPage from './pages/AboutPage';
import PrivacyPolicyPage from './pages/PrivacyPage';
import TermsOfServicePage from './pages/TermsPage';
import LocationPage from './pages/LocationsPage';
import AdminLocationsPage from './pages/AdminLocationsPage';
import LocationSubmissionPage from './pages/LocationSubmissionPage';
import SignupSigninPage from './pages/SignupSigninPage';
import LocationDetailsPage from './pages/LocationDetailsPage';
import AirportViewPage from './pages/AirportViewPage';
import AdminAirportSubmissionPage from './pages/AirportSubmissionPage';
import ProfilePage from './pages/ProfilePage';
import LocationSubmissionGuide from './pages/LocationSubmissionGuide';
import ContactPage from './pages/ContactPage';
import ComingSoonPage from './pages/ComingSoon';
import AircraftMovements from './pages/AircraftMovementsPage';
import AdminMovementsManagement from './pages/AdminMovementsPage';
import AirportLookupPage from './pages/AirportLookupPage';
import AdminImageReviewPage from './pages/AdminImagePage';
import LiveVatsimA380Count from './pages/LiveA380Page';
import StationSelector from './pages/StationSelector';
import RailwaySelector from './pages/GenerateRobloxTrackData';
import XMLProcessor from './pages/Convert';
import StopbarManager from './pages/ManageBars';
import StopbarMapper from './pages/VisualMatch';

const MovementWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      // Navigate to the movements page with a query parameter
      navigate(`/movements?openMovement=${id}`);
    }
  }, [id, navigate]);

  return null;
};

const App: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/tools/runways" element={<RunwayPredictionPage />} />
          <Route path="/tools/aircraft-identification" element={<AircraftIdentificationPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/locations" element={<LocationPage />} />
          <Route path="/location/:id" element={<LocationDetailsPage />} />
          <Route path="/locations/admin" element={<AdminLocationsPage />} />
          <Route path="/guide" element={<LocationSubmissionGuide />} />
          <Route path="/add-location" element={<LocationSubmissionPage />} />
          <Route path="/account" element={<SignupSigninPage />} />
          <Route path="/airport/:icao" element={<AirportViewPage />} />
          <Route path="/airports/admin" element={<AdminAirportSubmissionPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/soon" element={<ComingSoonPage />} />
          <Route path="/movements" element={<AircraftMovements />} />
          <Route path="/movements/manage" element={<AdminMovementsManagement />} />
          <Route path="/airports" element={<AirportLookupPage />} />
          <Route path="/movement/:id" element={<MovementWrapper />} />
          <Route path="/admin/images" element={<AdminImageReviewPage />} />
          <Route path="/vatsim/tracker" element={<LiveVatsimA380Count />} />
          <Route path="/transperth/pid" element={<StationSelector />} />
          <Route path="/railway" element={<RailwaySelector />} />
          <Route path="/stopbar" element={<XMLProcessor />} />
          <Route path="/stopbar/ids" element={<StopbarManager />} />
          <Route path="/stopbar/match" element={<StopbarMapper />} />
        </Routes>
      </Router>
  );
}

export default App;