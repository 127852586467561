import React, { useState, ChangeEvent } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import StopbarMapper from './VisualMatch';

interface ProcessedBar {
  name: string;
  runway: string;
  points: string[];
}

interface ProcessedData {
  version: string;
  encoding: string;
  bars: ProcessedBar[];
}

const XMLProcessor = () => {
  const [airportName, setAirportName] = useState('');
  const [xmlContent, setXmlContent] = useState<Document | null>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const convertCoordinate = (coord: number, isLat: boolean = false): string => {
    if (!validateCoordinate(coord, isLat)) {
      throw new Error(`Invalid coordinate value: ${coord}`);
    }

    const abs = Math.abs(coord);
    const degrees = Math.floor(abs);
    const minutes = Math.floor((abs - degrees) * 60);
    const seconds = ((abs - degrees) * 60 - minutes) * 60;
    
    const degStr = String(degrees).padStart(isLat ? 2 : 3, '0');

    return (coord < 0 ? '-' : '+') + 
           degStr +
           String(minutes).padStart(2, '0') +
           seconds.toFixed(3).padStart(6, '0');
  };

  const validateCoordinate = (coord: number, isLat: boolean): boolean => {
    const maxDegrees = isLat ? 90 : 180;
    return Math.abs(coord) <= maxDegrees;
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const content = e.target?.result;
        if (typeof content !== 'string') {
          throw new Error('Invalid file content');
        }
        
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, "text/xml");
        
        // Check for parsing errors
        const parserError = xmlDoc.querySelector('parsererror');
        if (parserError) {
          throw new Error('Invalid XML format');
        }
        
        setXmlContent(xmlDoc);
        setError('');
        setSuccess('XML file loaded successfully!');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to parse XML file');
        setSuccess('');
      }
    };
    reader.readAsText(file);
  };

  const processXML = () => {
    if (!xmlContent || !airportName) {
      setError('Please provide both XML file and airport name');
      return;
    }

    try {
      const processedData: ProcessedData = {
        version: '1.0',
        encoding: 'utf-8',
        bars: []
      };

      const polygons = xmlContent.getElementsByTagName('Polygon');
      
      for (let i = 0; i < polygons.length; i++) {
        const polygon = polygons[i];
        const displayName = polygon.getAttribute('displayName') || '';
        const [stopBar, runway] = displayName.split('-');
        const processedName = stopBar.replace(/ /g, '/');

        const vertices = polygon.getElementsByTagName('Vertex');
        const points: string[] = [];

        for (let j = 0; j < vertices.length; j++) {
          const vertex = vertices[j];
          const lat = parseFloat(vertex.getAttribute('lat') || '0');
          const lon = parseFloat(vertex.getAttribute('lon') || '0');
          
          const convertedLat = convertCoordinate(lat, true);
          const convertedLon = convertCoordinate(lon, false);
          points.push(convertedLat + convertedLon);
        }

        processedData.bars.push({
          name: processedName,
          runway: runway?.replace(/ /g, '/') || '',
          points
        });
      }

      // Create output XML
      const outputXML = `<?xml version="1.0" encoding="utf-8"?>
<Bars>
${processedData.bars.map(bar => `    <StopBar>
        <Name>${bar.name}</Name>
        <Runway>${bar.runway}</Runway>
${bar.points.map(point => `        <Point>${point}</Point>`).join('\n')}
    </StopBar>`).join('\n')}
</Bars>`;

      // Create and trigger download with airport name in filename
      const blob = new Blob([outputXML], { type: 'text/xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `StopBars_${airportName.trim()}.xml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setSuccess('XML processed and downloaded successfully!');
      setError('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      setSuccess('');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Card className="w-full max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle>XML Stop Bar Processor</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium">Airport Name</label>
            <Input
              type="text"
              value={airportName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAirportName(e.target.value)}
              placeholder="Enter airport name (e.g., YSSY-FlyTampa)"
              className="w-full"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium">Upload XML File</label>
            <Input
              type="file"
              accept=".xml"
              onChange={handleFileUpload}
              className="w-full"
            />
          </div>

          <Button
            onClick={processXML}
            disabled={!xmlContent || !airportName}
            className="w-full"
          >
            Process and Download XML
          </Button>

          {error && (
            <Alert className="bg-red-50">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="bg-green-50">
              <AlertTitle>Success</AlertTitle>
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
      <StopbarMapper />
    </div>
  );
};

export default XMLProcessor;