import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Upload, Download } from 'lucide-react';

interface FileEvent extends React.ChangeEvent<HTMLInputElement> {
  target: HTMLInputElement & EventTarget;
}

const StopbarManager: React.FC = () => {
  const [xmlContent, setXmlContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const processXML = (content: string): void => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/xml");
      
      if (doc.getElementsByTagName("parsererror").length > 0) {
        throw new Error("Invalid XML format");
      }

      // Add unique numbers to names
      const stopbars = doc.getElementsByTagName('StopBar');
      Array.from(stopbars).forEach((stopbar, index) => {
        const nameElement = stopbar.getElementsByTagName('Name')[0];
        if (nameElement && nameElement.textContent) {
          const uniqueNumber = String(index + 1).padStart(2, '0');
          nameElement.textContent = `${nameElement.textContent}--${uniqueNumber}`;
        }
      });

      const serializer = new XMLSerializer();
      setXmlContent(serializer.serializeToString(doc));
      setError(null);
    } catch (err) {
      setError("Error processing XML file. Please ensure it's a valid stopbar XML format.");
      console.error(err);
    }
  };

  const handleFileUpload = (event: FileEvent): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result && typeof e.target.result === 'string') {
        processXML(e.target.result);
      }
    };
    reader.onerror = () => setError("Error reading file");
    reader.readAsText(file);
  };

  const downloadXML = () => {
    if (!xmlContent) return;
    
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName.replace('.xml', '_updated.xml');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>Stopbar Name Generator</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-6">
            <label className="flex flex-col items-center p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
              <Upload className="w-8 h-8 text-gray-400 mb-2" />
              <span className="text-sm text-gray-500">Upload XML file</span>
              <input
                type="file"
                accept=".xml"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
              {error}
            </div>
          )}

          {xmlContent && (
            <button
              onClick={downloadXML}
              className="w-full flex items-center justify-center gap-2 p-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              <Download className="w-5 h-5" />
              Download Updated XML
            </button>
          )}

          {!xmlContent && !error && (
            <div className="text-center text-gray-500 my-8">
              Upload an XML file to generate unique stopbar names
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default StopbarManager;