import React, { useState, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

// Custom marker icons
const REFERENCE_ICON = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const TARGET_ICON = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const MAPPED_ICON = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

interface Coordinates {
  lat: number;
  lon: number;
}

interface StopBar {
  id: string;
  name: string;
  runway: string;
  points: string[];
  coords: Coordinates;
}

interface ViewportState {
  latitude: number;
  longitude: number;
  zoom: number;
}

interface Mapping {
  targetId: string;
  targetName: string;
  referenceName: string;
  targetCoords: Coordinates;
  referenceCoords: Coordinates;
}

const reconvertCoordinate = (coord: string): Coordinates => {
  const isLatNegative = coord.startsWith('-');
  const isLonNegative = coord.includes('-', 1);

  const parts = coord.split(/[+-]/).filter(Boolean);
  if (parts.length !== 2) {
    throw new Error(`Invalid coordinate format: ${coord}`);
  }

  const latDeg = parseFloat(parts[0].substring(0, 2));
  const latMin = parseFloat(parts[0].substring(2, 4));
  const latSec = parseFloat(parts[0].substring(4));

  const lonDeg = parseFloat(parts[1].substring(0, 3));
  const lonMin = parseFloat(parts[1].substring(3, 5));
  const lonSec = parseFloat(parts[1].substring(5));

  let latitude = latDeg + latMin / 60 + latSec / 3600;
  let longitude = lonDeg + lonMin / 60 + lonSec / 3600;

  if (isLatNegative) latitude = -latitude;
  if (isLonNegative) longitude = -longitude;

  return { lat: latitude, lon: longitude };
};

const StopbarMapper: React.FC = () => {
  const [referenceData, setReferenceData] = useState<StopBar[]>([]);
  const [targetData, setTargetData] = useState<StopBar[]>([]);
  const [mappings, setMappings] = useState<Mapping[]>([]);
  
  const [viewport, setViewport] = useState<ViewportState>({
    latitude: -33.933,
    longitude: 151.175,
    zoom: 14
  });
  const [draggedMarker, setDraggedMarker] = useState<StopBar | null>(null);
  const originalXMLRef = useRef<string>('');
  const [referenceFileName, setReferenceFileName] = useState<string>('');
  const [targetFileName, setTargetFileName] = useState<string>('');

  const parseXML = (xmlText: string, isReference: boolean): StopBar[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");
    const stopBars = Array.from(xmlDoc.getElementsByTagName("StopBar"));
    return stopBars.map((bar, index) => {
      const name = bar.getElementsByTagName("Name")[0]?.textContent || "";
      const paddedIndex = String(index + 1).padStart(2, '0');
      
      return {
        id: bar.getAttribute("id") || Math.random().toString(36).substr(2, 9),
        originalName: name,  // Store original name
        name: `${name}--${paddedIndex}`,
        runway: bar.getElementsByTagName("Runway")[0]?.textContent || "",
        points: Array.from(bar.getElementsByTagName("Point")).map(
          (point) => point.textContent || ""
        ),
        coords: reconvertCoordinate(
          Array.from(bar.getElementsByTagName("Point"))[1]?.textContent || ""
        ),
      };
    });
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    isReference: boolean
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    const text = await file.text();
    if (isReference) {
      setReferenceFileName(file.name);
    } else {
      originalXMLRef.current = text;
      setTargetFileName(file.name);
    }
    
    const data = parseXML(text, isReference);
  
    if (isReference) {
      setReferenceData(data);
    } else {
      setTargetData(data);
    }
  
    if (data.length > 0) {
      setViewport((prev) => ({
        ...prev,
        latitude: data[0].coords.lat,
        longitude: data[0].coords.lon
      }));
    }
  };

  const handleDragStart = (stopbar: StopBar) => {
    setDraggedMarker(stopbar);
  };

  const handleDrop = (targetStopbar: StopBar) => {
    if (!draggedMarker) return;

    const newMapping: Mapping = {
      targetId: targetStopbar.id,
      targetName: targetStopbar.name,
      referenceName: draggedMarker.name,
      targetCoords: targetStopbar.coords,
      referenceCoords: draggedMarker.coords
    };

    setMappings(prev => {
      const filtered = prev.filter(m => m.targetId !== targetStopbar.id);
      return [...filtered, newMapping];
    });

    setTargetData(prev => prev.map(sb => 
      sb.name === targetStopbar.name 
        ? { ...sb, coords: draggedMarker.coords }
        : sb
    ));

    setDraggedMarker(null);
  };

  const handleRemoveMapping = (targetId: string) => {
    setMappings(prev => prev.filter(m => m.targetId !== targetId));
  };

  const generateUpdatedXML = () => {
    if (!originalXMLRef.current) return null;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(originalXMLRef.current, "text/xml");
    const root = xmlDoc.documentElement;
    if (!root) return null;

    mappings.forEach(mapping => {
      const targetBaseName = mapping.targetName.split('--')[0];

      const stopBars = Array.from(xmlDoc.getElementsByTagName('StopBar'));
      const targetStopBar = stopBars.find(bar => {
        const nameEl = bar.getElementsByTagName('Name')[0];
        return nameEl?.textContent === targetBaseName;
      });
      const referenceStopBar = referenceData.find(sb => sb.name === mapping.referenceName);

      if (targetStopBar && referenceStopBar) {
        // Update name to reference name
        const nameEl = targetStopBar.getElementsByTagName('Name')[0];
        if (nameEl) {
          nameEl.textContent = mapping.referenceName;
        }

        // Update points
        const points = Array.from(targetStopBar.getElementsByTagName('Point'));
        referenceStopBar.points.forEach((point, index) => {
          if (points[index]) {
            points[index].textContent = point;
          }
        });
      }
    });

    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
  };

  const downloadWithIds = (xml: string, filename: string) => {
    const blob = new Blob([xml], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  const generateReferenceXML = () => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(originalXMLRef.current, "text/xml");
    const root = xmlDoc.documentElement;

    referenceData.forEach(ref => {
      const stopBars = Array.from(xmlDoc.getElementsByTagName('StopBar'));
      let stopBar = stopBars.find(bar => {
        const nameEl = bar.getElementsByTagName('Name')[0];
        return nameEl?.textContent === ref.name.split('--')[0];
      });
      
      if (!stopBar) {
        const indent = '\n        ';
        stopBar = xmlDoc.createElement('StopBar');
        root.appendChild(document.createTextNode('\n    '));
        
        const nameEl = xmlDoc.createElement('Name');
        nameEl.textContent = ref.name;
        stopBar.appendChild(document.createTextNode(indent));
        stopBar.appendChild(nameEl);

        const runwayEl = xmlDoc.createElement('Runway');
        runwayEl.textContent = ref.runway;
        stopBar.appendChild(document.createTextNode(indent));
        stopBar.appendChild(runwayEl);

        ref.points.forEach(point => {
          const pointEl = xmlDoc.createElement('Point');
          pointEl.textContent = point;
          stopBar?.appendChild(document.createTextNode(indent));
          stopBar?.appendChild(pointEl);
        });
        
        stopBar.appendChild(document.createTextNode('\n    '));
        root.appendChild(stopBar);
      } else {
        const nameEl = stopBar.getElementsByTagName('Name')[0];
        if (nameEl) {
          nameEl.textContent = ref.name;
        }
      }
    });
    
    root.appendChild(document.createTextNode('\n'));
    return new XMLSerializer().serializeToString(xmlDoc);
  };
  
  const handleDownload = () => {
    const updatedXML = generateUpdatedXML();
    const referenceXML = generateReferenceXML();
    
if (updatedXML) {
  const targetNewName = referenceFileName.replace(/\.xml$/, '_new.xml');
  downloadWithIds(updatedXML, targetNewName);
}
if (referenceXML) {
  const referenceNewName = targetFileName.replace(/\.xml$/, '_new.xml');
  downloadWithIds(referenceXML, referenceNewName);
}
  };

const findClosestStopbars = () => {
  const unmappedTargets = targetData.filter((target: StopBar) => 
    !mappings.some(m => m.targetName === target.name)
  );
  
  const unmappedReferences = referenceData.filter((ref: StopBar) => 
    !mappings.some(m => m.referenceName === ref.name)
  );

  unmappedTargets.forEach((target: StopBar) => {
    let closestRef: StopBar | null = null;
    let minDistance = Infinity;
    
    unmappedReferences.forEach((ref: StopBar) => {
      const distance = Math.sqrt(
        Math.pow(target.coords.lat - ref.coords.lat, 2) + 
        Math.pow(target.coords.lon - ref.coords.lon, 2)
      );
      
      if (distance < minDistance && distance < 0.0004) {
        minDistance = distance;
        closestRef = ref;
      }
    });
    
    if (closestRef) {
      const newMapping: Mapping = {
        targetId: target.id,
        targetName: target.name,
        referenceName: (closestRef as StopBar).name,
        targetCoords: target.coords,
        referenceCoords: (closestRef as StopBar).coords
      };
      
      setMappings(prev => [...prev, newMapping]);
      
      // Also update target coordinates to match reference
      setTargetData(prev => prev.map(sb => 
        sb.id === target.id 
          ? { ...sb, coords: closestRef!.coords }
          : sb
      ));
    }
  });
};

  const isAllMapped = targetData.every(target => 
    mappings.some(mapping => mapping.targetName === target.name)
  );

  return (
    <div className="flex flex-col h-screen p-4 bg-gray-100">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Stopbar Mapping Tool</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex gap-4">
            <div className="flex-1">
              <label className="block mb-2 font-medium">Reference XML</label>
              <input
                type="file"
                onChange={(e) => handleFileUpload(e, true)}
                accept=".xml"
                className="w-full p-2 border rounded"
              />
              <div className="mt-2 text-sm text-gray-500">
                {referenceData.length} stopbars loaded
              </div>
            </div>
            <div className="flex-1">
              <label className="block mb-2 font-medium">Target XML</label>
              <input
                type="file"
                onChange={(e) => handleFileUpload(e, false)}
                accept=".xml"
                className="w-full p-2 border rounded"
              />
              <div className="mt-2 text-sm text-gray-500">
                {targetData.length} stopbars loaded
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-4 gap-4 flex-grow">
        <Card className="col-span-3">
          <CardContent className="p-0">
            <div className="h-[600px] w-full">
              <MapContainer
                center={[viewport.latitude, viewport.longitude]}
                zoom={viewport.zoom}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                  {referenceData.map((stopbar) => {
                    const isMapped = mappings.some(m => m.referenceName === stopbar.name);
                    return (
                      <Marker 
                        key={`ref-${stopbar.name}`}
                        position={[stopbar.coords.lat, stopbar.coords.lon]}
                        icon={isMapped ? MAPPED_ICON : REFERENCE_ICON}
                        draggable={true}
                        zIndexOffset={1000}  // This keeps reference markers on top
                        eventHandlers={{
                          dragstart: () => handleDragStart(stopbar),
                          dragend: (e) => {
                            const target = targetData.find(t => 
                              Math.abs(t.coords.lat - e.target.getLatLng().lat) < 0.0001 &&
                              Math.abs(t.coords.lon - e.target.getLatLng().lng) < 0.0001
                            );
                            if (target) {
                              handleDrop(target);
                            }
                          }
                        }}
                      >
                        <Popup>
                          <div>
                            <strong className={isMapped ? "text-green-500" : "text-blue-500"}>{stopbar.name}</strong>
                            <div>Runway: {stopbar.runway}</div>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
                  {targetData.map((stopbar) => {
                    const mapping = mappings.find(m => m.targetName === stopbar.name);
                    return (
                      <Marker 
                        key={`target-${stopbar.name}`}
                        position={[stopbar.coords.lat, stopbar.coords.lon]}
                        icon={mapping ? MAPPED_ICON : TARGET_ICON}
                      >
                        <Popup>
                          <div>
                            <strong className={mapping ? "text-green-500" : "text-red-500"}>{stopbar.name}</strong>
                            <div>Runway: {stopbar.runway}</div>
                            <div>
                              Mapped to: {mapping?.referenceName || 'None'}
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
              </MapContainer>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Mappings</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {mappings.map(mapping => (
                <div
                  key={mapping.targetId}
                  className="flex justify-between items-center p-2 bg-gray-50 rounded"
                >
                  <div className="text-sm">
                    <span className="text-red-500">{mapping.targetName}</span>
                    <span className="mx-2">→</span>
                    <span className="text-blue-500">{mapping.referenceName}</span>
                  </div>
                  <button
                    onClick={() => handleRemoveMapping(mapping.targetName)}
                    className="text-red-400 hover:text-red-600 text-sm"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={findClosestStopbars}
              className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600 disabled:opacity-50"
              disabled={mappings.length === targetData.length}
            >
              Auto Map Similar Positions
            </button>
            <button
              onClick={handleDownload}
              className="mt-4 w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!isAllMapped}
              title={!isAllMapped ? "All stopbars must be mapped before downloading" : ""}
            >
              Download Updated XML
            </button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default StopbarMapper;